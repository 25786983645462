const baseUrl = process.env.REACT_APP_API_BASE_URL;

export async function createAppointment(appointment) {
    const response = await fetch(baseUrl + "appointment", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(appointment),
    });

    if (response.ok) return response.json();

    throw response;
}
