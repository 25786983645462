const baseUrl = process.env.REACT_APP_API_BASE_URL;

export async function createSession(session) {
    const response = await fetch(baseUrl + "sessions", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(session),
    });

    if (response.ok) return response.json();

    throw response;
}

export async function updateSession(sessionId, session) {
    const response = await fetch(`${baseUrl}sessions/${sessionId}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(session),
    });

    if (response.ok) return response.json();

    throw response;
}
