const baseUrl = process.env.REACT_APP_API_BASE_URL;

export async function createSessionProperty(sessionProperty) {
    const response = await fetch(baseUrl + "sessionproperties", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(sessionProperty),
    });

    if (response.ok) return response.json();

    throw response;
}
